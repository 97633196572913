import React, { useState, useEffect, useCallback } from 'react';
import './App.css';

function App() {
  const [publicIp, setPublicIp] = useState('');
  const [hostname, setHostname] = useState('');
  const [city, setCity] = useState('');
  const [downloadSpeed, setDownloadSpeed] = useState('');
  const [progress, setProgress] = useState(0);
  const [showProgress, setShowProgress] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [progressMessage, setProgressMessage] = useState('');

  const fetchIspInfo = useCallback((ip) => {
    fetch(`https://ipinfo.io/${ip}/json?token=5c2d06d161edcb`)
      .then(response => response.json())
      .then(data => {
        console.log('ISP Data:', data); // Log the ISP data
        setHostname(data.hostname || 'Não disponível');
        setCity(data.city || 'Não disponível');
      })
      .catch(error => {
        console.error('Erro ao obter informações do ISP:', error);
        setHostname('Não disponível');
        setCity('Não disponível');
      });
  }, []);

  const fetchPublicIp = useCallback(() => {
    fetch('https://api.ipify.org?format=json')
      .then(response => response.json())
      .then(data => {
        console.log('Public IP:', data.ip); // Log the public IP
        setPublicIp(data.ip);
        fetchIspInfo(data.ip);
      })
      .catch(error => {
        console.error('Erro ao obter o IP público:', error);
        setPublicIp('Não disponível');
        setHostname('Não disponível');
        setCity('Não disponível');
      });
  }, [fetchIspInfo]);

  const runSpeedTest = () => {
    setShowProgress(true);
    setDownloadSpeed('');
    setProgress(0);
    setDisableButton(true);
    setProgressMessage('Realizando teste...');

    const url = 'https://speed.cloudflare.com/__down?bytes=20000000'; // 20MB file for download test
    const estimatedTime = 8000; // Estimated time for the test in milliseconds
    const startTime = Date.now();

    const xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.responseType = 'blob';

    const intervalId = setInterval(() => {
      const elapsedTime = Date.now() - startTime;
      const progressPercent = (elapsedTime / estimatedTime) * 100;
      setProgress(Math.min(progressPercent, 100));

      if (elapsedTime >= estimatedTime) {
        clearInterval(intervalId);
      }
    }, 100); // Update progress every 100ms

    xhr.onload = () => {
      const endTime = Date.now();
      const duration = (endTime - startTime) / 1000; // duration in seconds
      const bitsLoaded = xhr.response.size * 8; // size in bits
      const speedBps = bitsLoaded / duration; // bits per second
      const speedKbps = speedBps / 1024;
      const speedMbps = speedKbps / 1024;

      console.log('Test Duration:', duration); // Log test duration
      console.log('Speed Mbps:', speedMbps); // Log the speed in Mbps

      setDownloadSpeed(`${speedMbps.toFixed(2)} Mbps`);
      setProgress(100);
      setProgressMessage('Teste Realizado');

      clearInterval(intervalId);

      setTimeout(() => {
        setShowProgress(false); // Reset showProgress after completing the test
        setDisableButton(false); // Enable the button after a delay
      }, 2000); // Adjusted to 2 seconds delay
    };

    xhr.onerror = () => {
      console.error('Erro ao testar velocidade da internet');
      setDownloadSpeed('Erro');
      setProgress(0);
      setProgressMessage('Erro ao testar a velocidade');
      setShowProgress(false);
      setDisableButton(false); 
      clearInterval(intervalId);
    };

    xhr.send();
  };

  useEffect(() => {
    fetchPublicIp();

    const ipInterval = setInterval(fetchPublicIp, 10000);

    return () => {
      clearInterval(ipInterval);
    };
  }, [fetchPublicIp]);

  return (
    <div className="container">
      <h1>MEU IP PÚBLICO</h1>
      <div className="flex-container">
        <div>
          <p className="public-ip"><h2>{publicIp}</h2></p>
          <p className="public-hostname"><h5>{hostname} - {city}</h5></p>
        </div>
      </div>
      <div className="center">
        <button onClick={runSpeedTest} disabled={disableButton}>Testar Velocidade da Internet</button>
      </div>
      {showProgress && (
        <div className="center">
          <div className="progress-bar">
            <div
              className="progress"
              style={{ width: `${progress}%` }}
            >
              <span className="centered-message">
                {progress === 0 ? progressMessage : `${progress.toFixed(2)}%`}
              </span>
            </div>
          </div>
        </div>
      )}
      {!showProgress && downloadSpeed && downloadSpeed !== 'Erro' && (
        <div className="center">
          <p className="public-ip">Download: {downloadSpeed}</p>
        </div>
      )}
      {!showProgress && downloadSpeed === 'Erro' && (
        <div className="center">
          <p>Ocorreu um erro ao testar a velocidade. Por favor, tente novamente.</p>
        </div>
      )}
      <div className="center">
        <h6>
          Versão 0.2.2 - Desenvolvido por <a href="http://www.sabha.com.br" target="_blank" rel="noopener noreferrer">Sabha Soluções em Tecnologia Ltda.</a>
        </h6>
      </div>
    </div>
  );
}

export default App;
